@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@100..900&family=Vollkorn:wght@600&family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,700,1,0');

body {
  font-family: 'Noto Sans', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: 'wdth' 100;
  color: #2e2e2e;
}
