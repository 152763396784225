.title {
  font-family: 'Vollkorn', serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: min(5vmax, 4rem);
  line-height: 1.2;
  color: #795548;
}

nav {
  padding: 40px 60px;
}

.icon {
  font-family: 'Material Symbols Outlined';
}

.top-right-button {
  position: fixed;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 999;
  color: #d9d9d9;
  font-size: 1.5rem;
  text-decoration: none;
  border: none;
  background: none;
  cursor: pointer;
}

.top-right-button:hover {
  color: #8d8d8d;
}
