.world {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.grass {
  position: absolute;
  width: 140%;
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%);
}

.grass > path {
  fill: #0d830d;
}

img.tree {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 2;
}

img.bird {
  width: 40px;
  position: absolute;
  transform: translate(-50%, 50%);
  cursor: pointer;
  z-index: 99;
}

img.bird-reversed {
  transform: scaleX(-1) translate(50%, 50%);
}

img.flower {
  position: absolute;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  -webkit-transform-origin: 0% 100%;
  transform-origin: 0% 100%;
  -webkit-animation: blossom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: blossom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  z-index: 3;
}

.tree-box-container {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 1;
}

img.flower-falling {
  -webkit-animation: falling 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: falling 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes blossom {
  0% {
    -webkit-transform: scale(0) rotate(-50deg) translate(-50%, 50%);
    transform: scale(0) rotate(-50deg) translate(-50%, 50%);
  }
  100% {
    -webkit-transform: scale(1) translate(-50%, 50%);
    transform: scale(1) translate(-50%, 50%);
  }
}

@keyframes blossom {
  0% {
    -webkit-transform: scale(0) rotate(-50deg) translate(-50%, 50%);
    transform: scale(0) rotate(-50deg) translate(-50%, 50%);
  }
  100% {
    -webkit-transform: scale(1) translate(-50%, 50%);
    transform: scale(1) translate(-50%, 50%);
  }
}

@-webkit-keyframes falling {
  0% {
    -webkit-transform: scale(1) translate(-50%, 50%);
    transform: scale(1) translate(-50%, 50%);
  }
  100% {
    -webkit-transform: scale(0) rotate(-50deg) translate(-50%, 50%);
    transform: scale(0) rotate(-50deg) translate(-50%, 50%);
  }
}

@keyframes falling {
  0% {
    -webkit-transform: scale(1) translate(-50%, 50%);
    transform: scale(1) translate(-50%, 50%);
  }
  100% {
    -webkit-transform: scale(0) rotate(-50deg) translate(-50%, 50%);
    transform: scale(0) rotate(-50deg) translate(-50%, 50%);
  }
}
